import classNames from "classnames"
import React from "react"
import { TechIcon } from "../.."
import "./Chip.scss"

const UIChip = ({ text, type, icon, className }) => {
  return (
    <div className={classNames(["chip", className])} aria-label={text}>
      {icon && (
        <TechIcon
          name={type}
          width="20"
          height="20"
          title={text}
          aria-label={text}
        />
      )}
      {text}
    </div>
  )
}

export default UIChip
