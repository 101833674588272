import React from "react"
import { Link } from "gatsby"

const isUrl = (url) => {
  const expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  const regex = new RegExp(expression)

  return regex.test(url)
}

const UILink = ({
  to,
  children,
  className,
  style,
  disabled,
  ...otherProps
}) => {
  const defProps = { className, style }
  const notEmpty = (v) => !!v && v !== "#"
  const TextLink = <b {...defProps}>{children}</b>
  switch (true) {
    case disabled:
      return TextLink

    case isUrl(to):
      return (
        <a {...defProps} href={to} target="_blank" rel="noopener">
          {children}
        </a>
      )

    case !isUrl(to) && notEmpty(to):
      return (
        <Link to={to} {...defProps} {...otherProps}>
          {children}
        </Link>
      )

    default:
      return TextLink
  }
}

export default UILink
