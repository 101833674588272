import React, { useContext, useEffect, useState } from "react"
import "./Navbar.scss"
import Logo from "./../../../static/img/logo.svg"
import Dialog from "@mui/material/Dialog"
import Menu from "../Menu/Menu"
import { Link } from "gatsby"
import { Context } from "../../store/ContextProvider"
import DarkModeToggle from "../UI/DarkModeToggle/DarkModeToggle"

function Navbar() {
  const {
    toggleMenu,
    state: { isMenuOpen },
  } = useContext(Context)

  return (
    <nav
      className="navbar"
      style={isMenuOpen ? { zIndex: "1400" } : { zIndex: "1300" }}
    >
      <div className="navbar-wrapper">
        <Link to="/">
          <Logo />
        </Link>
        <div className="navbar--right">
          <DarkModeToggle />
          <div id="menu_button" title={isMenuOpen ? "Close" : "Menu"}>
            <input
              type="checkbox"
              id="menu_checkbox"
              onClick={toggleMenu}
              checked={isMenuOpen}
              onChange={() => {}}
            />
            <label htmlFor="menu_checkbox" id="menu_label">
              <div id="menu_text_bar"></div>
            </label>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={isMenuOpen}
        className="menuDialog"
        BackdropProps={{ invisible: true }}
      >
        <Menu />
      </Dialog>
    </nav>
  )
}

export default Navbar
