import { Breadcrumbs } from "@mui/material"
import React from "react"
import { UILink } from ".."

const BreadCrumbs = ({ crumbs = {} }) => {
  const genBreadcrumbs = ({ current, parent }) => {
    const bcrumbs = [{ text: "Home", url: "/" }]
    if (!current) return bcrumbs

    if (parent) bcrumbs.push(parent)

    if (current)
      bcrumbs.push(typeof current === "string" ? { text: current } : current)

    return bcrumbs
  }

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {genBreadcrumbs(crumbs).map(({ text, url }, index) => {
        return (
          <UILink to={url} key={url + index}>
            {text}
          </UILink>
        )
      })}
    </Breadcrumbs>
  )
}

export default BreadCrumbs
