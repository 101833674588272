import { Slide } from "@mui/material"
import { Link } from "gatsby"
import { globalHistory } from "@reach/router"
import React, { useState, useEffect, useContext } from "react"
import { Context } from "../../store/ContextProvider"
import GridTapeEffect from "../../utils/GripTapeEffect"
import "./Menu.scss"
/* eslint-disable */
const Menu = () => {
  const {
    closeMenu,
    state: { isMenuOpen },
  } = useContext(Context)

  const [menu] = useState([
    { text: "Home", link: "/" },
    { text: "About", link: "/about" },
    { text: "Projects", link: "/projects" },
    { text: "Contact", link: "/contact" },
  ])

  const animateMenu = () => {
    const grid = new GridTapeEffect(".menu-list-item", {
      enterAnimation: true,
      feel: 0.2,
    })
    return grid
  }

  useEffect(() => {
    animateMenu()
    if (!isMenuOpen) animateMenu().kill()
  }, [isMenuOpen])

  useEffect(() => {
    globalHistory.listen(() => {
      if (isMenuOpen) closeMenu()
    })
  }, [])

  return (
    <Slide direction="left" in={isMenuOpen}>
      <div className="menu">
        <div className="menu-list" data-offset="10">
          {menu.map((data, i) => {
            return (
              <Link to={data.link} className="menu-list-item" key={data.text}>
                {data.text}
                <span className="Mask">
                  <span>{data.text}</span>
                </span>
                <span className="Mask">
                  <span>{data.text}</span>
                </span>
              </Link>
            )
          })}
        </div>
      </div>
    </Slide>
  )
}

export default Menu
