import React from "react"
import HTMLIcon from "../../static/img/icons/svg/html.svg"
import CSSIcon from "../../static/img/icons/svg/css.svg"
import SCSSIcon from "../../static/img/icons/svg/scss.svg"
import JavascriptIcon from "../../static/img/icons/svg/javascript.svg"
import VuejsIcon from "../../static/img/icons/svg/vuejs.svg"
import JQueryIcon from "../../static/img/icons/svg/jquery.svg"
import NuxtjsIcon from "../../static/img/icons/svg/nuxtjs.svg"
import ReactJsIcon from "../../static/img/icons/svg/reactjs.svg"
import NodejsIcon from "../../static/img/icons/svg/nodejs.svg"
import MongoDBIcon from "../../static/img/icons/svg/mongodb.svg"
import ExpressJsIcon from "../../static/img/icons/svg/expressjs.svg"
import GraphQLIcon from "../../static/img/icons/svg/graphql.svg"
import AxiosIcon from "../../static/img/icons/svg/axios.svg"
import GatsbyIcon from "../../static/img/icons/svg/gatsby.svg"
import ResumeIcon from "../../static/img/icons/svg/resume-cv.svg"
import PropTypes from "prop-types"

const Switch = (props) => {
  const { test, children } = props
  // filter out only children with a matching prop
  return children.find((child) => child.props?.["data-case"] === test) ?? null
}

// ;<TechIcon type="my.type" />

const TechIcon = ({ name, ...props }) => {
  return (
    <Switch test={name}>
      <JavascriptIcon data-case="javascript" {...props} />
      <CSSIcon data-case="css" {...props} />
      <HTMLIcon data-case="html" {...props} />
      <SCSSIcon data-case="scss" {...props} />
      <JQueryIcon data-case="jquery" {...props} />
      <VuejsIcon data-case="vuejs" {...props} />
      <NuxtjsIcon data-case="nuxtjs" {...props} />
      <ReactJsIcon data-case="reactjs" {...props} />
      <NodejsIcon data-case="nodejs" {...props} />
      <ExpressJsIcon data-case="expressjs" {...props} />
      <MongoDBIcon data-case="mongodb" {...props} />
      <GraphQLIcon data-case="graphql" {...props} />
      <AxiosIcon data-case="axios" {...props} />
      <GatsbyIcon data-case="gatsby" {...props} />
      <ResumeIcon data-case="resume" {...props} />
    </Switch>
  )
}

TechIcon.propTypes = {
  name: PropTypes.string,
}

export default TechIcon
