Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.default = void 0

var _gsap = require('gsap')

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    })
  } else {
    obj[key] = value
  }
  return obj
}

const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min) // Map number x from range [a, b] to [c, d]

const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c // Linear interpolation

const lerp = (a, b, n) => (1 - n) * a + n * b

const calcWinsize = () => {
  try {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  } catch {
    return undefined
  }
} // Gets the mouse position

const getMousePos = (e) => {
  let posX = 0
  let posY = 0
  posX = e.clientX + document.body.scrollLeft
  posY = e.clientY + document.body.scrollTop
  return {
    x: posX,
    y: posY,
  }
}

const windowHasMobileWidth = () => {
  try {
    if (typeof window !== `undefined`) return window.innerWidth <= 960
    else {
      return false
    }
  } catch {
    return false
  }
}

const getNavigator = () => {
  try {
    var _navigator

    return (_navigator = navigator) === null || _navigator === void 0
      ? void 0
      : _navigator.userAgent
  } catch {
    return undefined
  }
}

const isMobile = /Mobi/.test(getNavigator()) || windowHasMobileWidth()

class GridItem {
  constructor(el, options) {
    _defineProperty(this, 'frames', [])

    _defineProperty(this, 'winsize', calcWinsize())

    _defineProperty(this, 'mousepos', {
      x: this.winsize.width / 2,
      y: this.winsize.height / 2,
    })

    _defineProperty(this, 'conclusions', {
      hadNegativeY: null,
      hadNegativeX: null,
      dataSpeedX: null,
      dataSpeedY: null,
    })

    this.options = options
    this.move(el) // Calculate the viewport size

    window.addEventListener('resize', () => (this.winsize = calcWinsize())) // Track the mouse position

    window.addEventListener(
      'mousemove',
      (ev) => (this.mousepos = getMousePos(ev))
    )
  }

  getXYValue(el, key) {
    var _this$options, _this$options2

    const dataSpeed = el.getAttribute(`data-griptape-speed-${key}`)
    const ds = Number(dataSpeed)
    let value = 0

    if (
      (_this$options = this.options) !== null &&
      _this$options !== void 0 &&
      _this$options[`disable${key}`]
    ) {
      return value
    } else if (ds < 0) {
      this.conclusions[`hadNegative${key}`] = true
      value = Math.abs(ds)
    } else if (ds) {
      return ds
    } else if (
      (_this$options2 = this.options) !== null &&
      _this$options2 !== void 0 &&
      _this$options2.speed
    ) {
      var _this$options3

      return (_this$options3 = this.options) === null ||
        _this$options3 === void 0
        ? void 0
        : _this$options3.speed
    } else {
      value = getRandomNumber(50, 100)
    }

    this.conclusions[`dataSpeed${key}`] = value
    return value
  } // Move the items when moving the cursor

  move(el) {
    if (!el) return // amounts to move in each axis

    let translationVals = {
      tx: 0,
      ty: 0,
    } // get random start and end movement boundaries

    const xstart = this.getXYValue(el, 'X')
    const ystart = this.getXYValue(el, 'Y') // infinite loop

    const render = () => {
      // Calculate the amount to move.
      // Using linear interpolation to smooth things out.
      // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
      const dsXZero =
        this.conclusions.dataSpeedX && this.conclusions.dataSpeedX === 0
      if (!this.options.disableX || !dsXZero)
        translationVals.tx = lerp(
          translationVals.tx,
          map(
            this.mousepos.x,
            0,
            this.winsize.width / this.options.feel,
            -xstart,
            xstart
          ),
          this.options.delay
        )
      const dsYZero =
        this.conclusions.dataSpeedY && this.conclusions.dataSpeedY === 0
      if (!this.options.disableY || !dsYZero)
        translationVals.ty = lerp(
          translationVals.ty,
          map(
            this.mousepos.y,
            0,
            this.winsize.height / this.options.feel,
            -ystart,
            ystart
          ),
          this.options.delay
        )
      const xyValues = {
        x:
          this.options.negative || this.conclusions.hadNegativeX
            ? -translationVals.tx
            : translationVals.tx,
        y:
          this.options.negative || this.conclusions.hadNegativeY
            ? -translationVals.ty
            : translationVals.ty,
      }
      if (!this.options.manual) _gsap.gsap.set(el, xyValues)
      this.options.onProgress(xyValues)
      this.frames.push(requestAnimationFrame(render))
    }

    this.frames.push(requestAnimationFrame(render))
  }

  killAllRequestAnimationFrames() {
    if (this.frames.length > 1)
      for (const id of this.frames) cancelAnimationFrame(id)
  }
}

const defaultGridTapeOptions = {
  enterAnimation: false,
  disableY: false,
  disableX: false,
  negative: false,
  delay: 0.05,
  feel: 1,
  manual: false,
  onProgress: () => null,
}

class GripTapeEffect {
  constructor(
    /* Can be neither a react reference or a string selection */
    items,
    options,
    gridItems = []
  ) {
    this.items = items
    this.options = options
    this.gridItems = gridItems
    // Merge user options with defaults
    this.options = Object.assign({}, defaultGridTapeOptions, this.options) // Slides all elements from bottom, be sure to add overflow: hidden

    if (this.options.enterAnimation) this.showItems()
    if (!isMobile) this.buildTapeGrid()
  }

  buildTapeGrid() {
    // If given param a querystring
    if (typeof this.items === 'string')
      this.items = Array.from(document.querySelectorAll(this.items))
    if (Array.isArray(this.items))
      // Create mooving grid instance for each item
      [...this.items].forEach((item) =>
        this.gridItems.push(new GridItem(item, this.options))
      )
    else this.gridItems.push(new GridItem(this.items, this.options))
  }

  showItems() {
    _gsap.gsap.timeline().from(
      this.items,
      {
        autoAlpha: 0,
        yPercent: 60,
        duration: 1,
        // delay: 0.3,
        ease: _gsap.Back.easeInOut,
        stagger: {
          amount: 0.25,
          from: 'start',
        },
      },
      '>-.3'
    )
  }

  kill() {
    for (const el of this.gridItems) el.killAllRequestAnimationFrames()
  }
}

exports.default = GripTapeEffect
