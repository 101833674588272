import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames"

const Image = ({ image, alt, style, className, ...otherProps }) => {
  const img = getImage(image)
  const imageStyle = { width: "100%", ...style }
  const imageClass = classNames(["ui-image", className])

  if (!image || typeof image === "string") {
    return (
      <img className={imageClass} src={image} style={imageStyle} alt={alt} />
    )
  }

  return image ? (
    <GatsbyImage
      className={imageClass}
      image={img}
      style={imageStyle}
      alt={alt}
      {...otherProps}
    />
  ) : null
}

export default Image
