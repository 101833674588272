import { createTheme } from "@mui/material"

const typography = {
  fontFamily: `"Poppins", sans-serif`,
}

const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        userSelect: "none",
      },
    },
  },
}

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
}

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#52c6a1",
    },
    secondary: {
      main: "#0d261e",
    },
  },
  typography: {
    ...typography,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          backgroundColor: "#fffdf9",
          overflow: "hidden",
        },
      },
    },
    ...components,
  },
})

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#52c6a1",
    },
    secondary: {
      main: "#f2eee7",
    },
  },
  typography: {
    ...typography,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          backgroundColor: "#060b0d",
          overflow: "hidden",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#fffdf9",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: "#fffdf9",
            borderColor: "#fffdf9",
          },
        },
        input: {
          color: "#fffdf9",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#fffdf9 !important",
        },
      },
    },
    ...components,
  },
})

export { lightTheme, darkTheme }

const themeContext = createTheme(theme)
export default themeContext
