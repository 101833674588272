import React, { Component, createContext, useMemo } from "react"
import { lightTheme, darkTheme } from "../themes/index"
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import { Helmet } from "react-helmet"

export const Context = createContext()

export class ContextProvider extends Component {
  state = {
    isMenuOpen: false,
    isDarkThemeActive: false,
  }

  componentDidMount() {
    const preferDarkMedia = window.matchMedia("(prefers-color-scheme: dark)")
    this.retrieveActiveTheme(preferDarkMedia)

    preferDarkMedia.addEventListener("change", ({ matches }) =>
      this.applyTheme(matches)
    )
  }

  getTheme = (t) => (t ? "dark" : "light")

  applyTheme(isDark) {
    this.setBodyClass(isDark)
    this.setState({ isDarkThemeActive: isDark })
  }

  // Dark Mode
  /*
  1. First, it checks if the user has a theme saved in local storage. If not, it checks if the user prefers dark mode. If so, it applies the dark theme.
  2. If the user has a theme saved in local storage, it checks if the user prefers dark mode. If so, it applies the dark theme.
  3. If the user has a theme saved in local storage, it checks if the user prefers dark mode. If not, it applies the light theme.
  */
  retrieveActiveTheme = (preferDarkMedia) => {
    const storageTheme = JSON.parse(window.localStorage.getItem("theme"))
    const isDarkThemeActive = storageTheme === "dark"
    const supportDarkMode = preferDarkMedia.matches === true

    if (storageTheme === null && supportDarkMode) {
      this.applyTheme(supportDarkMode)
    } else if (!!storageTheme) {
      this.applyTheme(isDarkThemeActive)
    }
  }

  setBodyClass = (darkActive) => {
    document.body.classList.remove(`dark-mode`)
    document.body.classList.remove(`light-mode`)
    document.body.classList.add(`${this.getTheme(darkActive)}-mode`)
  }

  toggleActiveTheme = () => {
    this.setState((prevState) => {
      this.setBodyClass(!prevState.isDarkThemeActive)
      return { isDarkThemeActive: !prevState.isDarkThemeActive }
    })
    window.localStorage.setItem(
      "theme",
      JSON.stringify(this.getTheme(!this.state.isDarkThemeActive))
    )
  }

  // Menu

  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    })
  }

  closeMenu = () => {
    this.setState({
      isMenuOpen: false,
    })
  }

  render() {
    const { isDarkThemeActive } = this.state
    const currentActiveTheme = isDarkThemeActive ? darkTheme : lightTheme
    const currentThemeColor = isDarkThemeActive ? "#060b0d" : "#fffdf9"

    return (
      <Context.Provider
        value={{
          state: this.state,
          toggleMenu: this.toggleMenu,
          closeMenu: this.closeMenu,
          toggleTheme: this.toggleActiveTheme,
        }}
      >
        <ThemeProvider theme={currentActiveTheme}>
          <CssBaseline />
          <Helmet>
            <meta name="theme-color" content={currentThemeColor} />
          </Helmet>
          {this.props.children}
        </ThemeProvider>
      </Context.Provider>
    )
  }
}

export default ContextProvider
