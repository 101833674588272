import React from "react"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import GitHubIcon from "@mui/icons-material/GitHub"
import { UILink, Config } from "."

const Footer = () => {
  return (
    <footer className="footer">
      <div className="socials">
        <UILink to={Config.socials.linkedin}>
          <LinkedInIcon fontSize={"medium"} />
        </UILink>
        <UILink to={Config.socials.github}>
          <GitHubIcon fontSize={"medium"} />
        </UILink>
      </div>
      <div className="rights">
        <p>
          {Config.details.fullName} © {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  )
}

export default Footer
