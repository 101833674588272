import React from "react"
import ContextProvider from "./src/store/ContextProvider"
import { Layout } from "./src/components"

export const wrapPageElement = ({ element, props }) => {
  const layoutProps = element.type.layoutProps ?? {}
  return (
    <Layout {...props} {...layoutProps}>
      {element}
    </Layout>
  )
}

export const wrapRootElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>
}
